import { v4 as uuidv4 } from 'uuid';
// import { combineReducers } from 'redux'
import { cloneDeep, mapValues } from 'lodash';
import {serializeChartData} from '../actions/actionCreators';
import React, { Component } from 'react';
import {FlowChart} from "@mrblenny/react-flow-chart";
import {NodeCustom, NodeInnerCustom} from '../components/Card';
import {Dropdown,Button,ButtonGroup,Modal} from 'react-bootstrap';
import "./SurveyboardEditor.css";
import {EMPTY_CHART_DATA} from '../utils/constants'
import DismissibleAlert from '../components/DismissibleAlert'
import axios from 'axios';
import Papa from 'papaparse'; 


function collectBranches(chart, root, links, output) {
  let returnval = [];
  let isleaf = true;
  for (let x = 0; x < links.length; x++) {
    if (chart.links[links[x]].from.nodeId == root) {
      isleaf = false;
      let child = chart.links[links[x]].to.nodeId;
      let outputcpy = cloneDeep(output);
      let linkscpy = cloneDeep(links);
      linkscpy.splice(x, 1);
      outputcpy.push(child);
      let val = collectBranches(chart, child, linkscpy, outputcpy);
      returnval = returnval.concat(val);
    }
  }
  if (isleaf) {
    return [output];
  } else {
    return returnval;
  }
}

const get_root_node_ids = (chart) => {
  let roots = [];
  let node_list = Object.keys(chart.nodes);
  let link_list = Object.keys(chart.links);
  for (let x = 0; x < node_list.length; x++) {
    let is_root = true;
    for (let y = 0; y < link_list.length; y++) {
      if (chart.links[link_list[y]].to.nodeId == node_list[x]) {
        is_root = false;
        break;
      }
    }
    if (is_root) {
      roots.push(node_list[x]);
    }
  }
  return roots;
};


const decoupleChart = (chart) => {
  let roots = get_root_node_ids(chart);
  let link_list = Object.keys(chart.links);
  const initX = 50, initY = 50, xDist = 300, yDist = 150;

  let newlinks = {};
  let chains_of_nodeid = []; 
  for (let x = 0; x < roots.length; x++) {
    let chains = collectBranches(chart, roots[x], cloneDeep(link_list), [roots[x]]);
    // chains_of_nodeid.push(...chains);
    let newchains = [];
    let set = {};
    for (let i = 0; i < chains.length; i++) {
      let chain = chains[i];
      let newchain = [];
      for (let j = 0; j < chain.length; j++) {
        if (chain[j] in set) {
          let newid = uuidv4();
          let oldnode = chart.nodes[chain[j]];
          let newnode = cloneDeep(oldnode);

          newnode["id"] = newid;
          newnode.position.x = initX+j*xDist;
          newnode.position.y = initY+i*yDist;

          chart.nodes[newid] = newnode;
          newchain.push(newid);
          set[chain[j]] += 1;
        } else {
          set[chain[j]] = 1;
          newchain.push(chain[j]);
          chart.nodes[chain[j]].position.x = initX+j*xDist;
          chart.nodes[chain[j]].position.y = initY+i*yDist;
        }
      }
      newchains.push(newchain);
    }
    chains_of_nodeid.push(...newchains);
    for (let i = 0; i < newchains.length; i++) {
      let newchain = newchains[i];
      for (let j = 1; j < newchain.length; j++) {
        let oldlink = chart.links[link_list[0]];
        let newlink = cloneDeep(oldlink);
        let newid = uuidv4();

        newlink["id"] = newid;
        newlink.from.nodeId = newchain[j-1];
        newlink.to.nodeId = newchain[j];
        newlinks[newid] = newlink;

      }
    }
  }
  chart.links = newlinks;
  chart.chains_of_nodeid = chains_of_nodeid;
  return chart;
}


class SurveyboardEditor extends Component {
    constructor(props) {
        super(props);
        this.state={
          selectedDataPath:0,
          response_entries: []
        }
        this.handleDataPathChange = this.handleDataPathChange.bind(this)
    }

    handleDataPathChange(evtKey, evt){
      this.setState({
        selectedDataPath: evtKey
      });
    }

    render() {
        let {boardid, title, surveyChartData, stateActions, chartdata, step} = this.props;
        var numChains = 0;
        console.log(chartdata);
        // if((!chartdata || JSON.stringify(chartdata)==EMPTY_CHART_DATA) && (!surveyChartData || JSON.stringify(surveyChartData)==EMPTY_CHART_DATA)){
        //   return  <div className="storyboard_editor_div"></div>;
        // }
        if((!chartdata) && (!surveyChartData)){
          return  <div className="storyboard_editor_div"></div>;
        }
        if(!surveyChartData){
          surveyChartData = cloneDeep(chartdata);
          surveyChartData = decoupleChart(surveyChartData);
          numChains = surveyChartData.chains_of_nodeid.length;
          // console.log(surveyChartData.chains_of_nodeid);
          // console.log(JSON.stringify(surveyChartData));
        }
        let currentPath = surveyChartData.chains_of_nodeid[this.state.selectedDataPath];
        if(step ==3 ){
          surveyChartData["offset"]["x"] = -surveyChartData["nodes"][currentPath[0]]["position"]["x"]+30;
          surveyChartData["offset"]["y"] = -surveyChartData["nodes"][currentPath[0]]["position"]["y"]+30;
        }


        const exportStory = () => {
          let tobeexported = {"surveychart": JSON.stringify(surveyChartData), "surveyname": title};
          axios
          .post(window.BACKEND_ADDRESS + "/gen_survey", tobeexported)
          .then(response  => {
            console.log(response);
            console.log(response["data"]);
              var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response["data"]));
              var dlAnchorElem = document.getElementById('downloadAnchorElem');
              dlAnchorElem.setAttribute("href", dataStr );
              dlAnchorElem.setAttribute("download", title+".qsf");
              dlAnchorElem.click();
            }
          );
        };
    
        const selectResponses = (event) => {
          var {response_entries} = this.state;
          console.log(response_entries);
          for (var i = 0; i < event.target.files.length; i++) {
            Papa.parse(event.target.files[i], {
              header: true,
              complete: function(results) {
                var filtered = results.data.filter(function(x){return x.Finished == "1" || x.Finished == "Finished"});
                response_entries.push(...filtered)
              }
            });
          }
        };

        const submit = () => {
          var {response_entries} = this.state;
          console.log(response_entries);
          // let tobeexported = JSON.stringify(response_entries);
          // console.log(response_entries);
          axios
          .post(window.BACKEND_ADDRESS + "/board/responses/" + boardid, response_entries)
          .then(response  => {
            alert("" + response_entries.length + " entries have been uploaded.");
            }
          );
        }

        return (
          <div className="surveyboard_editor_div">
            {(()=>{
              switch(step) {
                case 2:
                  return(<div>
                    <DismissibleAlert text="In this stage, the storyboard is decoupled to show each unique data path for which the survey will be created. Click on Next to preview the survey."></DismissibleAlert>
                    <FlowChart chart={surveyChartData} callbacks={stateActions} 
                    Components={{ Node: NodeCustom, NodeInner: NodeInnerCustom}}/>
                    </div>);
                case 3:
                  return(
                    <div>
                      <Dropdown as={ButtonGroup} className="dropdown_menu" onSelect={this.handleDataPathChange}>  
                        <Button variant="secondary">Survey For:</Button>

                        <Dropdown.Toggle split variant="secondary" id="dropdown-split-data-path">{`Data Path ${parseInt(this.state.selectedDataPath)+1}`}</Dropdown.Toggle>
                        <Dropdown.Menu>
                        {[...Array(numChains).keys()].map(chainIdx => {
                          return (
                            <Dropdown.Item href="#/chain-${item}" key={chainIdx} eventKey={chainIdx}>{`Data Path ${chainIdx+1}`} </Dropdown.Item>
                          );
                        })}
                        </Dropdown.Menu>
                      </Dropdown>
                      <FlowChart chart={surveyChartData} callbacks={stateActions}
                        Components={{ Node: NodeCustom, NodeInner: NodeInnerCustom}} />
                      <div id="survey" className="survey_div"> 
                        <Modal.Dialog className="survey_modal" backdrop="static" scrollable={true}>
                          <Modal.Body>
                          
                            {[...Array(currentPath.length).keys()].map(cardIndx => {
                              return (
                                <div key={cardIndx} className="survey_input">
                                  <p className="survey_question">{`Q${cardIndx+1}.1. ${surveyChartData["nodes"][currentPath[cardIndx]]["type"]}`} <br/>
                                          {`${surveyChartData["nodes"][currentPath[cardIndx]]["properties"]["desc"]}`} <br/> <br/>
                                          {`How would you feel if the company collected your data as described above?`}</p>
                                          <input className="radiooption" type="radio" id="male" name="gender" value="male"/> Extremely comfortable <br/>
                                          <input className="radiooption" type="radio" id="male" name="gender" value="male"/> Somewhat comfortable <br/>
                                          <input className="radiooption" type="radio" id="male" name="gender" value="male"/> Neither comfortable nor uncomfortable <br/>
                                          <input className="radiooption" type="radio" id="male" name="gender" value="male"/> Somewhat uncomfortable <br/>
                                          <input className="radiooption" type="radio" id="male" name="gender" value="male"/> Extremely uncomfortable <br/>
                                  <br/>
                                  <p className="survey_question">{`Q${cardIndx+1}.2: Tell us why did you feel that way. Please explain your choice in a sentence starts with “I feel comfortable/uncomfortable/... because XXX” (100 characters minimum). Here are some example answers.`}</p>
                                  <textarea id="survey_answer_detail" className="form-control form-rounded pl-1 survey_input" rows="1" defaultValue="I feel uncomfortable because ..."></textarea>
                                  <br/> <br/>
                                </div>
                              );
                            })}
                          </Modal.Body>
                        </Modal.Dialog>
                      </div>
                    </div>);
                case 4: 
                    return(<div className="exportsurvey_div">
                      <div className="exportsurvey_header">Exporting the Survey file and testing with your users</div>
                      <div className="surveyexport_instruaction">
                        <p>You are now ready to send this survey to your users. Please read this <a href="https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/import-and-export-surveys/#ImportingASurvey" target="_blank"> instruction page</a> to understand how you can export the surveys from this platform and import it to the Qualtrics Survey Platform. 
                        </p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/H8VDOSimGk8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <p>
                            Once you export the survey you will not be able to edit your storyboard anymore. After exporting surveys, you can always copy your storyboard to a new file to start fresh.</p>
                        <button type="button"  onClick={exportStory}  className="btn btn-primary btn-exportsurvey">Export Surveys</button>
                        <a id="downloadAnchorElem"></a>
                      </div>
                    </div>);
              case 5: 
                  return(<div className="exportsurvey_div">
                      <div className="exportsurvey_header">Uploading the survey responses</div>
                      <div className="surveyexport_instruaction">
                        <p>If you have collected a few responses, you can export the response data from Qualtrics and import it below. Note that you may need to inspect the data file and manually remove noise rows (e.g., uncompleted rows). You can overwrite the earlier response data by uploading a new response file. </p>
                        <input className="btn_fileupload" type="file" name="file" onChange={selectResponses} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  multiple/> <br/> <br/>
                        <button type="button"  onClick={submit}  className="btn btn-primary btn-exportsurvey">Upload responses</button>
                      </div>
                  </div>);
            }})()}
          </div>

        );
    }
}

export default SurveyboardEditor;
